import React, { useCallback, useEffect, useRef } from 'react';
import styled from '@mui/material/styles/styled';
import { motion, useInView } from 'framer-motion';
import { useAngledBoop } from '../../utils/useBoop';
import { useBoop } from '../../utils/useBoop';
import StarPurple500Icon from '@mui/icons-material/StarPurple500';
import ButtonBase from '@mui/material/ButtonBase';


import img0 from "../../images/tree-h348-best/tree-cameraRaw-beige-h348.png"
import img1 from "../../images/tree-h348-best/tree-cameraRaw-burn-h348.png"
import img2 from "../../images/tree-h348-best/tree-cameraRaw-hardMix-h348.png"
import img3 from "../../images/tree-h348-best/tree-cameraRaw-hu-h348.png"
import img4 from "../../images/tree-h348-best/tree-cameraRaw-tyrkys-h348.png"

const arrImgs = [ img0, img1, img2, img3, img4 ]

const srcBgImgDefault = "/sf/im/paper_ff5.svg"




const CircleList = (props) => {
  // const srcBgImg = props.srcBgImg || srcBgImgDefault
  const { isBooped: isBooped1, variants: varBoop1, trigger: triggerBoop1 } = useAngledBoop({ index: 0, timeoutMs: 1000, animateTo: { scale: 1, rotateZ: 360 }, circleDiameterPx: 200, });
  const { isBooped: isBooped2, variants: varBoop2, trigger: triggerBoop2 } = useAngledBoop({
    index: 1, timeoutMs: 3500, animateTo: { scale: 2, rotateZ: 360 }, transitionTo: {
      // type: "spring",
      // stiffness: 100,
      // damping: 3,
      duration: 3.0,
    }, circleDiameterPx: 200,
  });
  const { isBooped: isBooped3, variants: varBoop3, trigger: triggerBoop3 } = useAngledBoop({ index: 2, timeoutMs: 2000, animateTo: { scale: 4, rotateZ: 360 }, circleDiameterPx: 200, });
  const { isBooped: isBooped4, variants: varBoop4, trigger: triggerBoop4 } = useAngledBoop({ index: 3, timeoutMs: 2500, animateTo: { scale: 4, rotateZ: 360 }, circleDiameterPx: 200, });
  const { isBooped: isBooped5, variants: varBoop5, trigger: triggerBoop5 } = useAngledBoop({ index: 4, timeoutMs: 3000, animateTo: { scale: 2, rotateZ: 360 }, circleDiameterPx: 200, });

  const { isBooped: isBoopedWrap, variants: varBoopWrap, trigger: triggerWrap } = useBoop({
    timeoutMs: 3000,
    animateTo:
    {

      y: [ 0, -100, -100 ],
      rotateZ: [ 0, 0, 360 ]
    }
    ,
    transitionTo: {
      type: "spring",
      stiffness: 100,
      damping: 3,
      duration: 1.0,
    },
    from: {
      y: [ -100, 0 ],
      rotateZ: [ 0, 0 ],
    },
  })

  const refWrap = useRef(null)
  const isInView = useInView(refWrap)

  const triggerCirclesAround = useCallback(() => {
    // If I had more than 5 points, I might write a `callAll()` helper function. But I don't, so this is fine.
    triggerWrap();
    triggerBoop1();
    triggerBoop2();
    triggerBoop3();
    triggerBoop4();
    triggerBoop5();
  }, [ triggerWrap, triggerBoop1, triggerBoop2, triggerBoop3, triggerBoop4, triggerBoop5 ])



  useEffect(() => {
    if (!isInView) return
    triggerCirclesAround()
  }, [ triggerCirclesAround, isInView ])

  const transformTemplate = ({ y, rotateZ }) => `rotateZ(${rotateZ}) translateY(${y})`

  return (
    <Wrapper ref={refWrap} >
      {isInView && (
        <>
          <Button
            onMouseEnter={
              // If I had more than 5 points, I might write a `callAll()` helper function. But I don't, so this is fine.
              triggerCirclesAround
            }
            onClick={
              triggerCirclesAround
            }
            className='clay'
            style={{
              transformStyle: 'preserve-3d',
              perspective: '300px'
            }}
          >
            <IconWrapper
              transformTemplate={transformTemplate}
              variants={varBoopWrap({ delayIndexDirectlyMs: 1000 })} //({ index: 1 })
              animate={isBoopedWrap ? "boop" : "rest"}
              style={{
                y: 0,
                rotateZ: 0,
              }}
            >
              <StarPurple500Icon className='clay' sx={{ fontSize: 48 }} />
            </IconWrapper>
          </Button>
          <CircleItem
            index={0}
            varBoop={varBoop1}
            isBooped={isBooped1}
            key={`CircleItem-0`}
          >
            <ImgStyled src={arrImgs[ 0 ]} />
          </CircleItem>

          <CircleItem
            index={1}
            varBoop={varBoop2}
            isBooped={isBooped2}
            key={`CircleItem-1`}
          >
            <ImgStyled src={arrImgs[ 1 ]} />
          </CircleItem>
          <CircleItem
            index={2}
            varBoop={varBoop3}
            isBooped={isBooped3}
            key={`CircleItem-2`}
          >
            <ImgStyled src={arrImgs[ 2 ]} />
          </CircleItem>
          <CircleItem
            index={3}
            varBoop={varBoop4}
            isBooped={isBooped4}
            key={`CircleItem-3`}
          >
            <ImgStyled src={arrImgs[ 3 ]} />
          </CircleItem>
          <CircleItem
            index={4}
            varBoop={varBoop5}
            isBooped={isBooped5}
            key={`CircleItem-4`}
          >
            <ImgStyled src={arrImgs[ 4 ]} />
          </CircleItem>
        </>
      )}
    </Wrapper>
  );
};

const ImgStyled = styled('img')({
  maxHeight: '100%',
  width: '100%',
});

// const Wrapper = styled.div`
//   position: relative;
//   margin: 0 auto;
//     height: 400px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     transform-style: preserve-3d;
//     perspective: 300px;
// `;

const Wrapper = styled("div")`
  position: relative;
  margin: 0 auto;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-style: preserve-3d;
    perspective: 300px;`
  ;

const Button = styled(ButtonBase)`
  position: relative;
  z-index: 3;
  padding: 8px;
  border-radius: 50%;
  transform-style: inherit;
  perspective: inherit;
`;
const IconWrapper = styled(motion.div)`
  display: block;
  transform-style: inherit;
  perspective: inherit;
  svg {
    display: block;
    stroke: red;
    fill: blue;
    transform-style: inherit;
  perspective: inherit;
  }
`;


const CircleItem = (props) => {

  const { index, varBoop, isBooped, children } = props
  return (
    <motion.div
      variants={varBoop({ index })}
      animate={isBooped ? "boop" : "rest"}
      className='clay'
      style={{
        position: 'absolute',
        zIndex: '1',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        width: '30px',
        height: '30px',
        margin: 'auto',
        borderRadius: '50%',
        padding: '5px',
        mixBlendMode: 'luminosity',
        transformStyle: 'inherit',
        perspective: 'inherit',
        textAlign: 'center',
      }}
    >
      {children}
    </motion.div>
  )
}

export default React.memo(CircleList);